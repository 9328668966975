<template>
  <div>
    <div v-if="loading == true">
      <div class="text-center">
        <b-spinner
          variant="primary"
          style="width: 3rem; height: 3rem"
        ></b-spinner>
      </div>
    </div>
    
    <div v-if="language === 'HE' && loading == false">
      <Header
        :allHotel="allHotels"
        @languageChanged="language = $event"
      ></Header>
      <div v-for="hotel in hotels" :key="hotel.hotel.wp_post_id">
        <div v-if="hotel.hotel.hotel_gallery">
          <div
            class="gallery_image_hotel aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
            v-if="hotel.hotel.hotel_gallery.resources.length >= 1"
          >
            <div class="by_image" id="video_gallery">
              <!-- <a v-if="!hotel.hotel.video_youtube_he" href="#" data-lity="" class="play-btn btn"></a> -->
              <a
                v-if="hotel.hotel.video_youtube_he"
                :href="
                  'https://www.youtube.com/watch?v=' +
                  hotel.hotel.video_youtube_he
                "
                data-lity=""
                class="play-btn btn"
              ></a>

              <img
                v-if="hotel.hotel.hotel_gallery.resources.length >= 1"
                :src="hotel.hotel.hotel_gallery.resources[0].src"
                alt="banner"
              />
            </div>

            <div class="by_image" @click="openGallery(1)">
              <img
                v-if="hotel.hotel.hotel_gallery.resources.length >= 2"
                :src="hotel.hotel.hotel_gallery.resources[1].src"
                alt="banner"
              />
            </div>
            <div class="by_image" @click="openGallery(2)">
              <img
                v-if="hotel.hotel.hotel_gallery.resources.length >= 3"
                :src="hotel.hotel.hotel_gallery.resources[2].src"
                alt="banner"
              />
            </div>

            <div class="by_image" @click="openGallery(3)">
              <img
                v-if="hotel.hotel.hotel_gallery.resources.length >= 4"
                :src="hotel.hotel.hotel_gallery.resources[3].src"
                alt="banner"
              />
            </div>

            <lightGallery
              :images="modalGallerys"
              ref="lightGallery"
              :show-caption="true"
              :show-thumbs="true"
              :show-light-box="false"
            ></lightGallery>
            <ul id="lightgallery" class="list-unstyled row"></ul>
            <div class="by_button" id="lightgallery_dynamic">
              <i class="demo-icon icon-th-list"></i> הצג גלריה
            </div>
          </div>
        </div>

        <div class="page width breadcromb_custom">
          <div class="breadcrumb" xmlns:v="https://rdf.data-vocabulary.org/#">
            <div typeof="v:Breadcrumb" class="breadcrumb_left">
              <a
                href="/"
                rel="v:url"
                property="v:title"
                title="מלונות בישראל"
                target="_blank"
                >ראשי</a
              >
              <em class="b-direction reversed"
                ><i class="demo-icon icon-angle-double-left"></i
              ></em>
              <br />
            </div>
            <div typeof="v:Breadcrumb" class="breadcrumb_left">
              <a
                href="#"
                rel="v:url"
                property="v:title"
                title="מלונות באילת"
                target="_blank"
                >מלונות באילת</a
              >
              <em class="b-direction reversed"
                ><i class="demo-icon icon-angle-double-left"></i
              ></em>
              <br />
            </div>
            <div typeof="v:Breadcrumb" class="breadcrumb_left">
              מלון אורכידאה אילת
            </div>
          </div>
          <div class="container" style="position: relative">
            <div id="sidebar">
              <div class="by_compo_hotel sidebar__inner">
                <div class="custom_inner">
               <div class="hotel_checkin custon_check">
            
                    <div class="border_style custom_border9">
                     
                      <strong>קבלה</strong>
                    </div>
                  </div>
                  <div class="hotel_checkout custom_border9 custon_check">
                    <div class="border_style">
                      <strong>לבדוק</strong>
                    </div>
                  </div>
                  </div>
                <form
                  name="mySearch"
                  id="by_search_form"
                  class="by_search_form_hotel"
                  method="get"
                  action=""
                  onsubmit="return validateFormArea('אנא בחר איזור','אנא בחבר תאריך כניסה','אנא בחר תאריך יציאה');"
                >
                  <!-- <div class="hotel_location">
                            <div class="border_style">
                                <strong>מלון</strong> אורכידאה אילת
                            </div>
                        </div> -->

                  <div class="t-datepicker custom_datepicker hevru_custom_date">
                    <div class="t-check-out custom_tcheck">
                      <div class="t-dates t-date-check-out">
                        <label class="t-date-info-title">Check Out </label>
                      </div>
                      <input
                        type="hidden"
                        class="t-input-check-out"
                        value="null"
                        name="end"
                      />
                    </div>
                    <div class="t-check-in">
                      <div class="t-dates t-date-check-in">
                        <label class="t-date-info-title">Check In</label>
                      </div>
                      <input
                        type="hidden"
                        class="t-input-check-in"
                        value="null"
                        name="start"
                      />
                      <div class="t-datepicker-day">
                        <table class="t-table-condensed"></table>
                      </div>
                    </div>
                  </div>

                
                  <!-- <div class="hotel_guests">
                    <div class="border_style">
                      <strong>אורחים</strong> בחירת אורחים
                    </div>
                  </div> -->
                  <input
                    type="hidden"
                    name="pax_group"
                    id="pax_group"
                    value="2|0|0"
                  />
                  <input
                    type="hidden"
                    name="currency"
                    value=""
                    id="currencyH"
                  />
                  <input type="hidden" name="areaCode" value="" id="areaCode" />
                  <input type="hidden" name="reff" value="advsrc" id="reff" />
                  <!-- <input type="hidden" name="promoted" value="3515" id="promoted"> <input type="submit" name="run" id="run" value="בדוק זמינות"> -->
                  <div class="col-md-12 custom_md custos_md_hav">
                    <div class="col-md-4 dropdown">
                      <select v-model="adults" id="select_adult">
                        <option disabled value="0" selected>מבוגרים</option>
                        <option value="1">מְבוּגָר 1</option>
                          <option value="2">מבוגרים 2</option>
                          <option value="3">מבוגרים 3</option>
                          <option value="4">מבוגרים 4</option>
                          <option value="5">מבוגרים 5</option>
                      </select>
                    </div>
                    

                      <div class="col-md-4 dropdown">
                        <select v-model="children" id="select_children">
                          <option disabled value="0" selected>ילדים</option>
                         <option value="1">יֶלֶד 1</option>
                          <option value="2">ילדים 2</option>
                          <option value="3">ילדים 3</option>
                          <option value="4">ילדים 4</option>
                          <option value="5">ילדים 5</option>
                        </select>
                      </div>
                     <div class="col-md-4 dropdown">
                        <select v-model="infants" id="select_infants">
                          <option disabled value="0" selected>תינוקות</option>
                          <option value="1">תינוקות 1</option>
                          <option value="2">תינוקות 2</option>
                          <option value="3">תינוקות 3</option>
                          <option value="4">תינוקות 4</option>
                          <option value="5">תינוקות 5</option>
                        </select>
                      </div>
                  </div>
                  <div class="by_search">
                    <i class="demo-icon icon-search-1"></i> חיפוש
                  </div>
                </form>

                <div class="by_highlights" v-if="hotel.hotel.hotel_highlights">
                  <h3 v-if="checkHightLights()">יתרונות המלון</h3>

                  <ul
                    v-for="(highlight, i) in hotel.hotel.hotel_highlights"
                    :key="i"
                  >
                    <li v-if="highlight.hotel_highlight_he">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M2.394 13.742L7.137 17.362 14.753 8.658 13.247 7.342 6.863 14.638 3.606 12.152zM21.753 8.658L20.247 7.342 13.878 14.621 13.125 14.019 11.875 15.581 14.122 17.379z"
                        ></path></svg
                      ><span>{{ highlight.hotel_highlight_he }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="content_h" id="hotel">
              <div
                class="hc_reviews hc_reviews_top"
                v-if="
                  hotel.hotel.hotel_reviews && hotel.hotel.hotel_reviews_total
                "
              >
                <span style="color: white"
                  >{{ hotel.hotel.hotel_reviews }}★</span
                ><br />(מ-{{ hotel.hotel.hotel_reviews_total }} דירוגים)
              </div>
              <h1 class="hotel_name_custom" v-if="hotel.hotel.hotel_name_he">
                {{ hotel.hotel.hotel_name_he }}
              </h1>
              <div class="by_stars" v-if="hotel.hotel.hotel_rank">
                {{ getRatingCount(hotel.hotel.hotel_rank) }}
              </div>
              <!-- <div class="by_stars" v-else>★★★★★</div> -->
              <address
                v-if="hotel.hotel.hotel_city_he || hotel.hotel.hotel_address_he"
              >
                <i class="demo-icon icon-location"></i
                ><span v-if="hotel.hotel.hotel_city_he">{{
                  hotel.hotel.hotel_city_he
                }}</span
                >,
                <span v-if="hotel.hotel.hotel_address_he">{{
                  hotel.hotel.hotel_address_he
                }}</span>
              </address>
              <div
                class="by_hotel_description"
                v-if="hotel.hotel.hotel_description_he"
              >
                <p>
                  {{ hotel.hotel.hotel_description_he }}
                </p>
              </div>
              <div class="by_more" v-if="hotel.hotel.hotel_description_he" style="cursor: pointer;">
              <span>הצג הכל</span><i class="demo-icon icon-angle-double-left"></i>
              </div>

              <div class="hotels_carousel" id="deals">
                <h2>מבצעים בלעדיים בהוטלס</h2>
                <p>כאן תוכלו למצוא את המבצעים המשתלמים ביותר</p>
                
                <b-card no-body>
                  <b-tabs>
                    <b-tab
                      no-body
                      title="השבוע"
                      id="tab1"
                      :title-link-class="linkClass(0)"
                      active
                     
                    >
                      <!-- This week promotion  -->
                      <div v-if="promotionLoading == true">
                        <div>
                          <!-- <b-spinner
                            variant="primary"
                            style="width: 3rem; height: 3rem"
                          ></b-spinner> -->
                          <ClipLoader :loading="promotionLoading"></ClipLoader>
                        </div>
                      </div>
                     
                      <div class="bytech-carousel by-carousel" v-show="promotionLoading == false">
                       
                        <div
                          class="item"
                          v-for="(deal, index) in thisWeekPromotionHe"
                          :key="index"
                        >
                          <div class="hc_item">
                            <div class="hc_img" v-if="deal.hotel_image">
                              <img :src="deal.hotel_image" alt="hotel" />
                            </div>
                            <div class="hc_info">
                              <a :href="deal.deal_url">
                                <div class="byb" v-if="deal.nights">
                                  {{ deal.nights }} לילות
                                </div>
                                <div class="hc_price">
                                  <span v-if="deal.currency">{{
                                    deal.currency
                                  }}</span
                                  ><span v-if="deal.minimumPrice">{{
                                    deal.minimumPrice
                                  }}</span>
                                  <s
                                    ><span v-if="deal.currency">{{
                                      deal.currency
                                    }}</span
                                    ><span v-if="deal.originalPrice">{{
                                      deal.originalPrice
                                    }}</span></s
                                  >
                                </div>
                                <p>
                                  מתאריך
                                  <span v-if="deal.fromDateD">{{
                                    deal.fromDateD
                                  }}</span>
                                </p>
                                <p>
                                  עד
                                  <span v-if="deal.toDateD">{{
                                    deal.toDateD
                                  }}</span>
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                  
                        <div
                          v-if="
                            promotionLoading == false &&
                            thisWeekPromotionHe.length == 0
                          "
                        >
                          <p>אין הצעות זמינות</p>
                        </div>
                      </div>
                    </b-tab>

                    <b-tab
                      no-body
                      title='סופ"ש הקרוב'
                      :title-link-class="promotion_tab"
                      @click="setLoad('comingWeekEnd')"
                    >
                      <!-- This coming weekend or holiday promotion -->
                      <div v-if="promotionLoading == true || defaultLoader==true">
                        <div>
                          <!-- <b-spinner
                            variant="primary"
                            style="width: 3rem; height: 3rem"
                          ></b-spinner> -->
                          <ClipLoader :loading="promotionLoading || defaultLoader"></ClipLoader>
                        </div>
                      </div>

                      <div class="bytech-carousel by-carousel" v-show="promotionLoading == false && defaultLoader == false">
                        <div
                          class="item"
                          v-for="(deal, index) in holidayPromotion"
                          :key="index"
                        >
                          <div class="hc_item">
                            <div class="hc_img" v-if="deal.hotel_image">
                              <img :src="deal.hotel_image" alt="hotel" />
                            </div>
                            <div class="hc_info">
                              <a :href="deal.deal_url">
                                <div class="byb" v-if="deal.nights">
                                  {{ deal.nights }} לילות
                                </div>
                                <div class="hc_price">
                                  <span v-if="deal.currency">{{
                                    deal.currency
                                  }}</span
                                  ><span v-if="deal.minimumPrice">{{
                                    deal.minimumPrice
                                  }}</span>
                                  <s
                                    ><span v-if="deal.currency">{{
                                      deal.currency
                                    }}</span
                                    ><span v-if="deal.originalPrice">{{
                                      deal.originalPrice
                                    }}</span></s
                                  >
                                </div>
                                <p>
                                  מתאריך
                                  <span v-if="deal.fromDateD">{{
                                    deal.fromDateD
                                  }}</span>
                                </p>
                                <p>
                                  עד
                                  <span v-if="deal.toDateD">{{
                                    deal.toDateD
                                  }}</span>
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="
                          promotionLoading == false && defaultLoader == false
                         && holidayPromotion.length == 0
                        "
                      >
                        <p>אין הצעות זמינות</p>
                      </div>
                    </b-tab>

                    <b-tab no-body title='סופ"ש הבא' class="promotion_tab" @click="setLoad('nextWeekEnd')">
                      <div v-if="promotionLoading == true || defaultLoader==true">
                        <div>
                          <!-- <b-spinner
                            variant="primary"
                            style="width: 3rem; height: 3rem"
                          ></b-spinner> -->
                          <ClipLoader :loading="promotionLoading || defaultLoader"></ClipLoader>
                        </div>
                      </div>
                      <div class="bytech-carousel by-carousel" v-show="promotionLoading == false && defaultLoader == false">
                        <div
                          class="item"
                          v-for="(deal, index) in nextWeekPromotion"
                          :key="index"
                        >
                          <div class="hc_item">
                            <div class="hc_img" v-if="deal.hotel_image">
                              <img :src="deal.hotel_image" alt="hotel" />
                            </div>
                            <div class="hc_info">
                              <a :href="deal.deal_url">
                                <div class="byb" v-if="deal.nights">
                                  {{ deal.nights }} לילות
                                </div>
                                <div class="hc_price">
                                  <span v-if="deal.currency">{{
                                    deal.currency
                                  }}</span
                                  ><span v-if="deal.minimumPrice">{{
                                    deal.minimumPrice
                                  }}</span>
                                  <s
                                    ><span v-if="deal.currency">{{
                                      deal.currency
                                    }}</span
                                    ><span v-if="deal.originalPrice">{{
                                      deal.originalPrice
                                    }}</span></s
                                  >
                                </div>
                                <p>
                                  מתאריך
                                  <span v-if="deal.fromDateD">{{
                                    deal.fromDateD
                                  }}</span>
                                </p>
                                <p>
                                  עד
                                  <span v-if="deal.toDateD">{{
                                    deal.toDateD
                                  }}</span>
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="
                          promotionLoading == false && defaultLoader == false &&
                           nextWeekPromotion.length == 0
                        "
                      >
                        <p>אין הצעות זמינות</p>
                      </div>
                    </b-tab>
                  </b-tabs>
                </b-card>
                <div class="hc_click">
                  <a href=""
                    >לעמוד מבצעים מרוכז לחצו כאן
                    <i class="fal fa-angle-double-left"></i
                  ></a>
                </div>
              </div>

              <div
                class="by_rooms"
                id="rooms"
                v-if="hotel.rooms && hotel.rooms.length > 0"
              >
                <!-- {{room}} -->

                <h2>חדרי המלון</h2>

                <div
                  class="by_room by_room_scroll_div"
                  v-if="hotel.rooms && hotel.rooms.length > 0"
                >
                  <div
                    class="by_room"
                    v-for="(room, index) in hotel.rooms"
                    :key="index"
                  >
                    <!-- {{room}} -->
                    <div class="by_room_img" v-if="room.room_image">
                      <img :src="room.room_image" alt="hotel" />
                    </div>
                    <div class="by_room_img" v-else>
                      <img src="../assets/image-not-found.png" alt="hotel" />
                    </div>
                    <div class="by_room_txt">
                      <h3 v-if="room.name_he">{{ room.name_he }}</h3>
                      <!-- <h3>{{room.name_he}}</h3> -->
                      <!-- <p>חדר משפחתי גדול המחולק לשניים ע"י ארון דו-צדדי, בצידו האחד של הארון מיטה לזוג ובצידו השני 2 ספות נפתחות (כפולה+בודדת). בנוסף קיים מטבחון בחדר. החדר מותאם לזוג+3 ילדים או לזוג+2 ילדים+תינוק.</p> -->
                      <p v-if="room.description_he">{{ room.description_he }}</p>
                      <div
                        class="by_room_more"
                        v-if="room.description_he"
                        @click="setRoom(room)"
                      >
                        פרטים נוספים
                        <i class="demo-icon icon-angle-double-left"></i>
                      </div>
                      <div class="by_room_info_bg">
                        <div class="by_room_info">
                          <div class="by_room_info_colse">x סגור</div>

                          <div class="by_room_info_box">
                            <div class="by_room_info_title">
                              {{ roomData.name_he }}
                            </div>
                            <!-- <p>חדר משפחתי גדול המחולק לשניים ע"י ארון דו-צדדי, בצידו האחד של הארון מיטה לזוג ובצידו השני 2 ספות נפתחות (כפולה+בודדת). בנוסף קיים מטבחון בחדר. החדר מותאם לזוג+3 ילדים או לזוג+2 ילדים+תינוק.</p> -->
                            <p v-if="roomData.description_he">
                              {{ roomData.description_he }}
                            </p>
                          </div>

                          <div class="by_room_info_box">
                            <div
                              class="by_room_info_title"
                              v-if="room.room_size"
                            >
                              גודל החדר
                            </div>
                            <ul>
                              <li v-if="room.room_size">
                                <i class="demo-icon icon-resize-full"></i
                                ><span>{{ roomData.room_size }}</span>
                              </li>
                            </ul>
                            <!-- ul -->
                          </div>
                          <!-- by_room_info_box -->

                          <div
                            class="by_room_info_box"
                            v-if="hotel.hotel.hotel_facilities !== undefined"
                          >
                            <div class="by_room_info_title">שרותי החדר</div>
                            <ul v-if="hotel.hotel.hotel_facilities.length > 0">
                              <li
                                v-for="(facilities, index) in hotel.hotel
                                  .hotel_facilities"
                                :key="index"
                              >
                                <span v-if="facilities.value !== false">
                                  {{ facilities.name }}</span
                                >
                                <!-- {{facilities.value}} -->
                              </li>
                              <!-- <li>
                                <i class="demo-icon icon-television"></i>
                                טלוויזיה
                              </li>
                              <li>
                                <i class="demo-icon icon-fog-sun"></i> מרפסת
                              </li>
                              <li>
                                <i class="demo-icon icon-bed"></i> מיטה זוגית
                              </li>
                              <li>
                                <i class="demo-icon icon-snowflake-o"></i> מזגן
                              </li>
                              <li>
                                <i class="demo-icon icon-swimming"></i> בריכה
                              </li>
                              <li>
                                <i class="demo-icon icon-coffee"></i> ערכת קפה
                              </li>
                              <li>
                                <i class="demo-icon icon-angle-double-left"></i>
                                אינטרנט חינם
                              </li>
                              <li>
                                <i class="demo-icon icon-wheelchair"></i> נגיש
                                לנכים
                              </li> -->
                            </ul>
                            <!-- ul -->
                          </div>
                          <!-- by_room_info_box -->
                        </div>
                        <!-- by_room_info -->
                      </div>
                      <!-- by_room_info_bg -->
                    </div>
                  </div>

                  <!-- by_room_img -->

                  <!-- by_room_img -->
                </div>
              </div>
              <!-- by_rooms -->

              <div
                class="by_type_reviews"
                id="reviews"
                v-if="hotel.hotel.last_hotel_rates"
              >
                <div class="by_type_reviews_top">
                  <h2 v-if="hotel.hotel.hotel_name_he">
                    ביקורות של {{ hotel.hotel.hotel_name_he }}
                  </h2>
                  <div
                    class="by_review_star"
                    v-if="
                      hotel.hotel.hotel_reviews !== '' &&
                      hotel.hotel.hotel_reviews_total !== ''
                    "
                  >
                    <i class="demo-icon icon-star-1"></i>
                    {{ hotel.hotel.hotel_reviews }}
                    <!-- <a href="#">
                      (מתוך {{ hotel.hotel.hotel_reviews_total }} ביקורות)</a
                    > -->
                     <span style="color:black">
                      (מתוך {{ hotel.hotel.hotel_reviews_total }} ביקורות)</span>
                  </div>
                </div>

                <div
                  class="all_type_reviews"
                  v-if="cleanlinessService !== false"
                >
                  <!-- div for cleanliness -->
                  <div class="by_type_review">
                    נקיון
                    <div class="by_type_review_num">
                      <div class="l_num">{{ cleanlinessService }}</div>
                      <div :class="`num_${cleanlinessService}`">
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div class="by_type_review" v-if="foodService !== false">
                    <!-- div for food -->
                    אוכל
                    <div class="by_type_review_num">
                      <div class="l_num">{{ foodService }}</div>
                      <div :class="`num_${foodService}`"><span></span></div>
                    </div>
                  </div>
                  <div class="by_type_review" v-if="locationService !== false">
                    <!-- div for hotal location -->
                    מיקום המלון
                    <div class="by_type_review_num">
                      <div class="l_num">{{ locationService }}</div>
                      <div :class="`num_${locationService}`"><span></span></div>
                    </div>
                  </div>
                  <div class="by_type_review" v-if="totalService !== false">
                    <!-- div for hotal service -->
                    שירות
                    <div class="by_type_review_num">
                      <div class="l_num">{{ totalService }}</div>
                      <div :class="`num_${totalService}`"><span></span></div>
                    </div>
                  </div>
                  <div
                    class="by_type_review"
                    v-if="publicAreaService !== false"
                  >
                    <!-- div for public area service -->
                    אזורים ציבוריים
                    <div class="by_type_review_num">
                      <div class="l_num">{{ publicAreaService }}</div>
                      <div :class="`num_${publicAreaService}`">
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="by_type_review"
                    v-if="roomQualityService !== false"
                  >
                    <!-- div for Room qality service -->
                    איכות החדר
                    <div class="by_type_review_num">
                      <div class="l_num">{{ roomQualityService }}</div>
                      <div :class="`num_${roomQualityService}`">
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="by_reviews"
                  v-if="hotel.hotel.last_hotel_rates.length > 0"
                >
                  <div
                    class="by_review"
                    v-if="hotel.hotel.last_hotel_rates.length >= 1"
                  >
                    <i class="demo-icon icon-user"></i>

                    <h4>{{ hotel.hotel.last_hotel_rates[0].Full_Name }}</h4>
                    <div class="by_review_date">
                      {{
                        getMomentDate(
                          hotel.hotel.last_hotel_rates[0].Rate_Date,
                          "DD/MM/YYYY"
                        )
                      }}
                    </div>
                    <p>
                      <!-- user comments will add here -->
                    </p>
                  </div>
                  <div
                    class="by_review"
                    v-if="hotel.hotel.last_hotel_rates.length >= 2"
                  >
                    <i class="demo-icon icon-user"></i>
                    <h4>{{ hotel.hotel.last_hotel_rates[1].Full_Name }}</h4>
                    <div class="by_review_date">
                      {{
                        getMomentDate(
                          hotel.hotel.last_hotel_rates[1].Rate_Date,
                          "DD/MM/YYYY"
                        )
                      }}
                    </div>
                    <p>
                      <!-- user comments will add here -->
                    </p>
                  </div>
                  <div
                    class="by_review"
                    v-if="hotel.hotel.last_hotel_rates.length >= 3"
                  >
                    <i class="demo-icon icon-user"></i>
                    <h4>{{ hotel.hotel.last_hotel_rates[2].Full_Name }}</h4>
                    <div class="by_review_date">
                      {{
                        getMomentDate(
                          hotel.hotel.last_hotel_rates[2].Rate_Date,
                          "DD/MM/YYYY"
                        )
                      }}
                    </div>
                    <p>
                      <!-- user comments will add here -->
                    </p>
                  </div>
                  <div
                    class="by_review"
                    v-if="hotel.hotel.last_hotel_rates.length >= 4"
                  >
                    <i class="demo-icon icon-user"></i>
                    <h4>{{ hotel.hotel.last_hotel_rates[3].Full_Name }}</h4>
                    <div class="by_review_date">
                      {{
                        getMomentDate(
                          hotel.hotel.last_hotel_rates[3].Rate_Date,
                          "DD/MM/YYYY"
                        )
                      }}
                    </div>
                    <p>
                      <!-- user comments will add here -->
                    </p>
                  </div>
                  <div class="by_review_button">
                    <a href="#"
                      >טען תגובות נוספות
                      <i class="demo-icon icon-left-open-big"></i
                    ></a>
                  </div>
                </div>
              </div>

              <div
                class="by_map"
                id="location"
                v-if="hotel.hotel.latitude && hotel.hotel.longitude"
              >
                <h2>מיקום המלון</h2>

                <div style="height: 450px; width: 100%; float: left">
                  <LMap
                    :zoom="15"
                    :center="[
                      parseFloat(hotel.hotel.latitude),
                      parseFloat(hotel.hotel.longitude),
                    ]"
                  >
                    <LTileLayer :url="lmapURL"></LTileLayer>
                    <LMarker
                      :lat-lng="[
                        parseFloat(hotel.hotel.latitude),
                        parseFloat(hotel.hotel.longitude),
                      ]"
                    >
                      <LIcon
                        ><span
                          ><i
                            class="fa fa-map-marker"
                            style="color: red; font: bold"
                            aria-hidden="true"
                          ></i
                          >{{ hotel.hotel.hotel_name_he }}</span
                        ></LIcon
                      >
                    </LMarker>
                  </LMap>
                </div>
                <!-- <p>
                  המלון ממוקם בחוף אלמוג , מול המצפה התת ימי . על חוף אלמוג מול
                  הים , בין הרי המדבר האדמדמים ומי המפרץ התכולים, שוכן לו גן עדן
                  קסום- מלון אורכידאה! -חוף המגדלור לשרות אורחי המלון, נמצא
                  במרחק 500 מטר מהמלון, הכניסה ללא תשלום, בהצגת כרטיס החדר.
                </p> -->
              </div>
              <!-- by_map -->
            </div>
          </div>
          <!-- <div class="important">
          <h2>דברים שחשוב לדעת</h2>
          <div class="by_lists">
            <h3>כללי</h3>
            <div class="by_list">
              <i class="demo-icon icon-clock"></i> צ'ק-אין: 14:00 - 18:00
            </div>
            <div class="by_list">
              <i class="demo-icon icon-school"></i> מתאים לילדים ולתינוקות
            </div>
            <div class="by_list">
              <i class="demo-icon icon-clock"></i> ללא עישון
            </div>
            <div class="by_list">
              <i class="demo-icon icon-paw"></i> מותר להביא חיות מחמד
            </div>
            <a href="#inline" data-lity>הצג הכל</a>
            <div id="inline" class="lity-hide">
              <h3>כללי</h3>
              <div class="by_list">
                <i class="demo-icon icon-clock"></i> צ'ק-אין: 14:00 - 18:00
              </div>
              <div class="by_list">
                <i class="demo-icon icon-school"></i> מתאים לילדים ולתינוקות
              </div>
              <div class="by_list">
                <i class="demo-icon icon-clock"></i> ללא עישון
              </div>
              <div class="by_list">
                <i class="demo-icon icon-paw"></i> מותר להביא חיות מחמד
              </div>
            </div>
          </div>
          <div class="by_lists">
            <h3>בריאות ובטיחות</h3>
            <div class="by_list">
              <i class="demo-icon icon-clock"></i> צ'ק-אין: 14:00 - 18:00
            </div>
            <div class="by_list">
              <i class="demo-icon icon-school"></i> מתאים לילדים ולתינוקות
            </div>
            <div class="by_list">
              <i class="demo-icon icon-clock"></i> ללא עישון
            </div>
            <div class="by_list">
              <i class="demo-icon icon-paw"></i> מותר להביא חיות מחמד
            </div>
            <a href="#inline2" data-lity>הצג הכל</a>
            <div id="inline2" class="lity-hide">
              <h3>בריאות ובטיחות</h3>
              <div class="by_list">
                <i class="demo-icon icon-clock"></i> צ'ק-אין: 14:00 - 18:00
              </div>
              <div class="by_list">
                <i class="demo-icon icon-school"></i> מתאים לילדים ולתינוקות
              </div>
              <div class="by_list">
                <i class="demo-icon icon-clock"></i> ללא עישון
              </div>
              <div class="by_list">
                <i class="demo-icon icon-paw"></i> מותר להביא חיות מחמד
              </div>
            </div>
          </div>
          <div class="by_lists">
            <h3>מדיניות ביטולים</h3>
            <div class="by_list" v-if="hotel.hotel.cancellation_terms_he">

              <p style="white-space: pre-line">{{hotel.hotel.cancellation_terms_he}}</p>

              <a href="#inline3" data-lity>לפרטים נוספים</a>
              <div id="inline3" class="lity-hide">
                <div class="by_list">
                  <h3>מדיניות ביטולים</h3>
                 
                   <p style="white-space: pre-line">{{hotel.hotel.cancellation_terms_he}}</p>
                  <div class="note lightblue">
                    <strong>אישור הזמנה</strong> החזר כספי מלא אם הצ'ק-אין הוא
                    בעוד 14 ימים לפחות
                  </div>
                  <div class="note yellow">
                    <strong>48 שעות מאוחר יותר</strong> החזר כספי בגובה 50%,
                    בניכוי דמי שירות
                  </div>
                  <div class="note red">
                    <strong>יום לפני ההגעה</strong> אין החזר כספי
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="resize-sensor"></div>
        </div> -->
          <!-- <div class="by_faq">
          <h2>שאלות נפוצות</h2>
          <ul class="accordion">
            <li>
              <div class="arrow">
                <i class="demo-icon icon-down-open-big"></i>
              </div>
              <a class="toggle" href="javascript:void(0);"
                >מתי משלמים עבור ההזמנה?</a
              >
              <div class="inner">
                <p>אתם אמורים לשלם בכניסה למלון בזמן הצ'ק אין</p>
              </div>
            </li>

            <li>
              <div class="arrow">
                <i class="demo-icon icon-down-open-big"></i>
              </div>
              <a class="toggle" href="javascript:void(0);"
                >האם אפשר להביא חיות למלון?</a
              >
              <div class="inner">
                <p>
                  במלון חל איסור בעלי חיים מלבד אנשים עם צרכים מיוחדים בעלי
                  אישור
                </p>
              </div>
            </li>
            <li>
              <div class="arrow">
                <i class="demo-icon icon-down-open-big"></i>
              </div>
              <a class="toggle" href="javascript:void(0);"
                >מתי משלמים עבור ההזמנה?</a
              >
              <div class="inner">
                <p>אתם אמורים לשלם בכניסה למלון בזמן הצ'ק אין</p>
              </div>
            </li>

            <li>
              <div class="arrow">
                <i class="demo-icon icon-down-open-big"></i>
              </div>
              <a class="toggle" href="javascript:void(0);"
                >האם אפשר להביא חיות למלון?</a
              >
              <div class="inner">
                <p>
                  במלון חל איסור בעלי חיים מלבד אנשים עם צרכים מיוחדים בעלי
                  אישור
                </p>
              </div>
            </li>
          </ul>
        </div> -->
        </div>
        <div class="by_more_hotels" v-if="moreHotel.length > 0">
          <div class="width">
            <h3>מלונות נוספים באילת</h3>
            <div
              class="by_more_hotel"
              v-for="(more, index) in moreHotel"
              :key="index"
              style="cursor: pointer"
              @click="goToHotel(more.hotel_id)"
            >
              <div
                class="by_more_hotel_image"
                v-if="more.img_src && more.img_src !== ''"
              >
                <img :src="more.img_src" alt="banner" />
              </div>
              <div
                class="by_more_hotel_txt"
                v-if="more.title_he && more.title_he !== ''"
              >
                <h4>{{ more.title_he }}</h4>
                <p v-if="more.address_he && more.address_he !== ''">
                  {{ more.address_he }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="error_msg" v-if="errorMsg">{{ errorMsg }}</div>
      <Footer />
    </div>
    <div v-if="language === 'EN' && loading == false" lang="en" dir="ltr">
      <Header
        :allHotel="allHotels"
        @languageChanged="language = $event"
      ></Header>
      <div v-for="hotel in hotels" :key="hotel.hotel.wp_post_id">
        <div v-if="hotel.hotel.hotel_gallery">
          <div
            class="gallery_image_hotel aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
            v-if="hotel.hotel.hotel_gallery.resources.length >= 1"
          >
            <div class="by_image" id="video_gallery">
              <a
                v-if="hotel.hotel.video_youtube_en"
                :href="
                  'https://www.youtube.com/watch?v=' +
                  hotel.hotel.video_youtube_en
                "
                data-lity=""
                class="play-btn btn"
              ></a>

              <img
                v-if="hotel.hotel.hotel_gallery.resources.length >= 1"
                :src="hotel.hotel.hotel_gallery.resources[0].src"
                alt="banner"
              />
            </div>

            <div class="by_image" @click="openGallery(1)">
              <img
                v-if="hotel.hotel.hotel_gallery.resources.length >= 2"
                :src="hotel.hotel.hotel_gallery.resources[1].src"
                alt="banner"
              />
            </div>
            <div class="by_image" @click="openGallery(2)">
              <img
                v-if="hotel.hotel.hotel_gallery.resources.length >= 3"
                :src="hotel.hotel.hotel_gallery.resources[2].src"
                alt="banner"
              />
            </div>

            <div class="by_image" @click="openGallery(3)">
              <img
                v-if="hotel.hotel.hotel_gallery.resources.length >= 4"
                :src="hotel.hotel.hotel_gallery.resources[3].src"
                alt="banner"
              />
            </div>

            <!-- </b-carousel> -->
            <lightGallery
              :images="modalGallerys"
              ref="lightGallery"
              :show-caption="true"
              :show-thumbs="true"
              :show-light-box="false"
            ></lightGallery>
            <ul id="lightgallery" class="list-unstyled row"></ul>
            <div class="by_button" id="lightgallery_dynamic">
              <i class="demo-icon icon-th-list"></i> הצג גלריה
            </div>
          </div>
        </div>

        <div class="page_en width breadcromb_custom">
          <div class="breadcrumb custom_bread" xmlns:v="https://rdf.data-vocabulary.org/#">
            <div typeof="v:Breadcrumb" class="breadcrumb_left">
              <a
                href="/"
                rel="v:url"
                property="v:title"
                title="מלונות בישראל"
                target="_blank"
                >main</a
              >
              <em class="b-direction reversed"
                ><i class="demo-icon icon-angle-double-left"></i
              ></em>
              <br />
            </div>
            <div typeof="v:Breadcrumb" class="breadcrumb_left">
              <a
                href="#"
                rel="v:url"
                property="v:title"
                title="מלונות באילת"
                target="_blank"
                >Hotels in Eilat</a
              >
              <em class="b-direction reversed"
                ><i class="demo-icon icon-angle-double-left"></i
              ></em>
              <br />
            </div>
            <div typeof="v:Breadcrumb" class="breadcrumb_left">
              {{ hotel.hotel.hotel_name_en }}
            </div>
          </div>

          <div class="container" style="position: relative">
            <div id="sidebar" class="custom_sidebar pull-left">
              <div class="by_compo_hotel sidebar__inner">
              <div class="custom_inner">
               <div class="hotel_checkin custon_check">
            
                    <div class="border_style custom_border9">
                     
                      <strong>Check In</strong>
                    </div>
                  </div>
                  <div class="hotel_checkout custom_border9 custon_check">
                    <div class="border_style">
                      <strong>Check out</strong>
                    </div>
                  </div>
                  </div>
                <form
                  name="mySearch"
                  id="by_search_form"
                  class="by_search_form_hotel"
                  method="get"
                  action=""
                  onsubmit="return validateFormArea('אנא בחר איזור','אנא בחבר תאריך כניסה','אנא בחר תאריך יציאה');"
                >
                  <!-- {{date}} -->

                  <div class="t-datepicker custom-datepicker custom_englis5">
                    <div class="t-check-in custom_checkout">
                      <div class="t-dates t-date-check-in">
                        <label class="t-date-info-title">Check In </label>
                      </div>
                      <input
                        type="hidden"
                        class="t-input-check-in"
                        value="null"
                        name="start"
                      />
                      <div class="t-datepicker-day">
                        <table class="t-table-condensed"></table>
                      </div>
                    </div>
                    <div class="t-check-out">
                      <div class="t-dates t-date-check-out">
                        <label class="t-date-info-title">Check Out</label>
                      </div>
                      <input
                        type="hidden"
                        class="t-input-check-out"
                        value="null"
                        name="end"
                      />
                    </div>
                  </div>
                 
                  <!-- <div class="hotel_guests">
                    <div class="border_style">
                      <strong>Guest</strong> Guest selection
                    </div>
                  </div> -->
                  <input
                    type="hidden"
                    name="pax_group"
                    id="pax_group"
                    value="2|0|0"
                  />
                  <input
                    type="hidden"
                    name="currency"
                    value=""
                    id="currencyH"
                  />
                  <input type="hidden" name="areaCode" value="" id="areaCode" />
                  <input type="hidden" name="reff" value="advsrc" id="reff" />
                  <!-- <input type="hidden" name="promoted" value="3515" id="promoted"> <input type="submit" name="run" id="run" value="בדוק זמינות"> -->
                  <div class="col-md-12 custom_md">
                    <div class="col-md-4 dropdown">
                      <select v-model="adults" id="select_adult">
                        <option disabled value="0" selected>Adults</option>
                        <option value="1">Adult 1</option>
                        <option value="2">Adults 2</option>
                        <option value="3">Adults 3</option>
                        <option value="4">Adults 4</option>
                        <option value="5">Adults 5</option>
                      </select>
                    </div>
                    <div class="col-md-4 dropdown">
                      <select v-model="children" id="select_children">
                        <option disabled value="0" selected>Children</option>
                        <option value="1">Child 1</option>
                        <option value="2">Childern 2</option>
                        <option value="3">Childern 3</option>
                        <option value="4">Childern 4</option>
                        <option value="5">Childern 5</option>
                      </select>
                    </div>

                    <div class="col-md-4 dropdown">
                      <select v-model="infants" id="select_infants">
                        <option disabled value="0" selected>Infant</option>
                        <option value="1">Infant 1</option>
                        <option value="2">Infant 2</option>
                        <option value="3">Infant 3</option>
                        <option value="4">Infant 4</option>
                        <option value="5">Infant 5</option>
                      </select>
                    </div>
                  </div>

                  <div class="by_search">
                    <i class="demo-icon icon-search-1"></i> Search
                  </div>
                </form>

                <div class="by_highlights" v-if="hotel.hotel.hotel_highlights">
                  <h3 v-if="checkHightLights()">Hotel advantages</h3>

                  <ul
                    v-for="(highlight, i) in hotel.hotel.hotel_highlights"
                    :key="i"
                  >
                    <li v-if="highlight.hotel_highlight_en">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M2.394 13.742L7.137 17.362 14.753 8.658 13.247 7.342 6.863 14.638 3.606 12.152zM21.753 8.658L20.247 7.342 13.878 14.621 13.125 14.019 11.875 15.581 14.122 17.379z"
                        ></path></svg
                      ><span>{{ highlight.hotel_highlight_en }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="content_h custom_hotel" id="hotel">
              <div
                class="hc_reviews hc_reviews_top custom_top87 pull-right"
                v-if="
                  hotel.hotel.hotel_reviews && hotel.hotel.hotel_reviews_total
                "
              >
                <span style="color: white"
                  >{{ hotel.hotel.hotel_reviews }}★</span
                >
                <br />(From {{ hotel.hotel.hotel_reviews_total }} Ratings)
              </div>
              <h1 class="hotel_name_custom" v-if="hotel.hotel.hotel_name_en">
                {{ hotel.hotel.hotel_name_en }}
              </h1>
              <div class="by_stars" v-if="hotel.hotel.hotel_rank">
                {{ getRatingCount(hotel.hotel.hotel_rank) }}
              </div>
              <!-- <div class="by_stars" v-else>★★★★★</div> -->
              <address
                v-if="hotel.hotel.hotel_city_en || hotel.hotel.hotel_address_en"
              >
                <i class="demo-icon icon-location"></i
                ><span v-if="hotel.hotel.hotel_city_en">{{
                  hotel.hotel.hotel_city_en
                }}</span
                >,
                <span v-if="hotel.hotel.hotel_address_en">{{
                  hotel.hotel.hotel_address_en
                }}</span>
              </address>
              <div
                class="by_hotel_description"
                v-if="hotel.hotel.hotel_description_en"
              >
                <p>
                  {{ hotel.hotel.hotel_description_en }}
                </p>
              </div>
              <div class="by_more" v-if="hotel.hotel.hotel_description_en" style="cursor:pointer">
               <span>Show all</span><i class="demo-icon icon-angle-double-left"></i>
              </div>

              <div class="hotels_carousel" id="deals">
                <h2>Exclusive deals at hotels</h2>
                <p>Here you will find the most lucrative deals</p>

                <b-card no-body>
                  <b-tabs>
                    <b-tab
                      no-body
                      title="This week"
                      id="tab1"
                      :title-link-class="linkClass(0)"
                      active
                    >
                      <!-- This week promotion  -->
                      <div v-if="promotionLoading == true">
                        <div>
                          <!-- <b-spinner
                            variant="primary"
                            style="width: 3rem; height: 3rem"
                          ></b-spinner> -->
                          <ClipLoader :loading="promotionLoading"></ClipLoader>
                        </div>
                      </div>
                      <div class="bytech-carousel by-carousel" v-show="promotionLoading == false">
                        <div
                          class="item"
                          v-for="(deal, index) in thisWeekPromotionEn"
                          :key="index"
                        >
                          <div class="hc_item_en">
                            <div class="hc_img" v-if="deal.hotel_image">
                              <img :src="deal.hotel_image" alt="hotel" />
                            </div>
                            <div class="hc_info_en custom_infoer" >
                              <a :href="deal.deal_url">
                                <div class="byb" v-if="deal.nights">
                                  for {{ deal.nights }} nights
                                </div>
                                <div class="hc_price">
                                  <span v-if="deal.currency">{{
                                    deal.currency
                                  }}</span
                                  ><span v-if="deal.minimumPrice">{{
                                    deal.minimumPrice
                                  }}</span>
                                  <s
                                    ><span v-if="deal.currency">{{
                                      deal.currency
                                    }}</span
                                    ><span v-if="deal.originalPrice">{{
                                      deal.originalPrice
                                    }}</span></s
                                  >
                                </div>
                                <p>
                                  From
                                  <span v-if="deal.fromDateD">{{
                                    deal.fromDateD
                                  }}</span>
                                </p>
                                <p>
                                  To
                                  <span v-if="deal.toDateD">{{
                                    deal.toDateD
                                  }}</span>
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="
                             promotionLoading == false 
                         &&
                            thisWeekPromotionEn.length == 0
                          "
                        >
                          <p>No offers are available</p>
                        </div>
                      </div>
                    </b-tab>

                    <b-tab
                      no-body
                      title="This coming weekend"
                      :title-link-class="promotion_tab"
                      @click="setLoad('comingWeekEnd')"
                    >
                 
                      <!-- This coming weekend or holiday promotion -->
                      <div v-if="promotionLoading == true || defaultLoader==true">
                        <div>
                          <!-- <b-spinner
                            variant="primary"
                            style="width: 3rem; height: 3rem"
                          ></b-spinner> -->
                          <ClipLoader :loading="promotionLoading || defaultLoader"></ClipLoader>
                        </div>
                      </div>

                      <div class="bytech-carousel by-carousel" v-show="promotionLoading == false && defaultLoader == false">
                        <div
                          class="item"
                           v-for="(deal, index) in holidayPromotion"
                          :key="index">
                      
                          <div class="hc_item_en">
                            <div class="hc_img" v-if="deal.hotel_image">
                              <img :src="deal.hotel_image" alt="hotel" />
                            </div>
                            <div class="hc_info_en custom_infoer">
                              <a :href="deal.deal_url">
                                <div class="byb" v-if="deal.nights">
                                  For {{ deal.nights }} nights
                                </div>
                                <div class="hc_price">
                                  <span v-if="deal.currency">{{
                                    deal.currency
                                  }}</span
                                  ><span v-if="deal.minimumPrice">{{
                                    deal.minimumPrice
                                  }}</span>
                                  <s
                                    ><span v-if="deal.currency">{{
                                      deal.currency
                                    }}</span
                                    ><span v-if="deal.originalPrice">{{
                                      deal.originalPrice
                                    }}</span></s
                                  >
                                </div>
                                <p>
                                  From
                                  <span v-if="deal.fromDateD">{{
                                    deal.fromDateD
                                  }}</span>
                                </p>
                                <p>
                                  To
                                  <span v-if="deal.toDateD">{{
                                    deal.toDateD
                                  }}</span>
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                      
                      </div>

                      <div
                        v-if="
                          promotionLoading == false && defaultLoader == false
                         && holidayPromotion.length == 0
                        "
                      >
                        <p>No offers are available</p>
                      </div>
                    </b-tab>

                    <b-tab no-body title="Next weekend" class="promotion_tab" @click="setLoad('nextWeekEnd')">
                      <div v-if="promotionLoading == true || defaultLoader==true">
                        <div>
                          <!-- <b-spinner
                            variant="primary"
                            style="width: 3rem; height: 3rem"
                          ></b-spinner> -->
                          <ClipLoader :loading="promotionLoading || defaultLoader"></ClipLoader>
                        </div>
                      </div>
                      <div class="bytech-carousel by-carousel" v-show="promotionLoading == false && defaultLoader == false">
                        <div
                          class="item"
                          v-for="(deal, index) in nextWeekPromotion"
                          :key="index"
                        >
                          <div class="hc_item_en">
                            <div class="hc_img" v-if="deal.hotel_image">
                              <img :src="deal.hotel_image" alt="hotel" />
                            </div>
                            <div class="hc_info_en custom_infoer">
                              <a :href="deal.deal_url">
                                <div class="byb" v-if="deal.nights">
                                  For {{ deal.nights }} nights
                                </div>
                                <div class="hc_price">
                                  <span v-if="deal.currency">{{
                                    deal.currency
                                  }}</span
                                  ><span v-if="deal.minimumPrice">{{
                                    deal.minimumPrice
                                  }}</span>
                                  <s
                                    ><span v-if="deal.currency">{{
                                      deal.currency
                                    }}</span
                                    ><span v-if="deal.originalPrice">{{
                                      deal.originalPrice
                                    }}</span></s
                                  >
                                </div>
                                <p>
                                  From
                                  <span v-if="deal.fromDateD">{{
                                    deal.fromDateD
                                  }}</span>
                                </p>
                                <p>
                                  To
                                  <span v-if="deal.toDateD">{{
                                    deal.toDateD
                                  }}</span>
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="
                          promotionLoading == false && defaultLoader == false
                         &&
                          nextWeekPromotion.length == 0
                        "
                      >
                        <p>No offers are available</p>
                      </div>
                    </b-tab>
                  </b-tabs>
                </b-card>
                <div class="hc_click">
                  <a href=""
                    >For a concentrated promotions page, click here
                    <i class="fal fa-angle-double-left"></i
                  ></a>
                </div>
              </div>

              <div
                class="by_rooms"
                id="rooms"
                v-if="hotel.rooms && hotel.rooms.length > 0"
              >
                <!-- {{room}} -->

                <h2>Hotel rooms</h2>

                <div
                  class="by_room by_room_scroll_div"
                  v-if="hotel.rooms && hotel.rooms.length > 0"
                >
                  <div
                    class="by_room"
                    v-for="(room, index) in hotel.rooms"
                    :key="index"
                  >
                    <!-- {{room}} -->
                    <div class="by_room_img_en" v-if="room.room_image">
                      <img :src="room.room_image" alt="hotel" />
                    </div>
                    <div class="by_room_img_en" v-else>
                      <img src="../assets/image-not-found.png" alt="hotel" />
                    </div>
                    <div class="by_room_txt custom_roomtext">
                      <h3 v-if="room.name_en">{{ room.name_en }}</h3>
                      
                      <p v-if="room.description_en">{{ room.description_en }}</p>
                      <div
                        class="by_room_more"
                        v-if="room.description_en"
                        @click="setRoom(room)"
                      >
                        <!-- פרטים נוספים -->
                        More details
                        <i class="demo-icon icon-angle-double-left"></i>
                      </div>
                      <div class="by_room_info_bg">
                        <div class="by_room_info">
                          <div class="by_room_info_colse">x closed</div>

                          <div class="by_room_info_box">
                            <div class="by_room_info_title">
                              {{ roomData.name_en }}
                            </div>
                            <!-- <p>חדר משפחתי גדול המחולק לשניים ע"י ארון דו-צדדי, בצידו האחד של הארון מיטה לזוג ובצידו השני 2 ספות נפתחות (כפולה+בודדת). בנוסף קיים מטבחון בחדר. החדר מותאם לזוג+3 ילדים או לזוג+2 ילדים+תינוק.</p> -->

                            <p v-if="roomData.description_en">
                              {{ roomData.description_en }}
                            </p>
                            <!-- <p>description</p> -->
                          </div>

                          <div class="by_room_info_box">
                            <div
                              class="by_room_info_title"
                              v-if="roomData.room_size"
                            >
                              Room size
                            </div>
                            <ul>
                              <li v-if="roomData.room_size">
                                <i class="demo-icon icon-resize-full"></i
                                ><span>{{ roomData.room_size }}</span>
                              </li>
                            </ul>
                            <!-- ul -->
                          </div>
                          <!-- by_room_info_box -->

                          <div class="by_room_info_box" v-if="hotel.hotel.hotel_facilities !== undefined">
                            <div class="by_room_info_title">Room services</div>
                            <ul v-if="hotel.hotel.hotel_facilities.length > 0">
                              <li
                                v-for="(facilities, index) in hotel.hotel
                                  .hotel_facilities"
                                :key="index"
                              >
                                <span v-if="facilities.value !== false">
                                  {{ facilities.name }}</span
                                >
                                <!-- {{facilities.value}} -->
                              </li>
                              <!-- <li>
                                <i class="demo-icon icon-television"></i> TV
                              </li>
                              <li>
                                <i class="demo-icon icon-fog-sun"></i> Terrace
                              </li>
                              <li>
                                <i class="demo-icon icon-bed"></i> Double bed
                              </li>
                              <li>
                                <i class="demo-icon icon-snowflake-o"></i>
                                Air-Conditioner
                              </li>
                              <li>
                                <i class="demo-icon icon-swimming"></i> Pool
                              </li>
                              <li>
                                <i class="demo-icon icon-coffee"></i> coffee set
                              </li>
                              <li>
                                <i class="demo-icon icon-angle-double-left"></i>
                                Free internet
                              </li>
                              <li>
                                <i class="demo-icon icon-wheelchair"></i>
                                Accessible to the disabled
                              </li> -->
                            </ul>
                          </div>
                          <!-- by_room_info_box -->
                        </div>
                        <!-- by_room_info -->
                      </div>
                      <!-- by_room_info_bg -->
                    </div>
                  </div>

                  <!-- by_room_img -->

                  <!-- by_room_img -->
                </div>
              </div>
              <!-- by_rooms -->

              <div
                class="by_type_reviews"
                id="reviews"
                v-if="hotel.hotel.last_hotel_rates"
              >
                <div class="by_type_reviews_top">
                  <h2 v-if="hotel.hotel.hotel_name_en">
                    Reviews of the {{ hotel.hotel.hotel_name_en }}
                  </h2>
                  <div
                    class="by_review_star"
                    v-if="
                      hotel.hotel.hotel_reviews !== '' &&
                      hotel.hotel.hotel_reviews_total !== ''
                    "
                  >
                    <i class="demo-icon icon-star-1"></i
                    >{{ hotel.hotel.hotel_reviews }}
                    <!-- <a href="#">
                      (out of {{ hotel.hotel.hotel_reviews_total }} reviews)</a
                    > -->
                    <span style="color:black">
                      (out of {{ hotel.hotel.hotel_reviews_total }} reviews)</span>
                  </div>
                </div>

                <div
                  class="all_type_reviews custom_type_review"
                  v-if="cleanlinessService !== false"
                >
                  <!-- div for cleanliness -->
                  <div class="by_type_review">
                    Cleanliness
                    <div class="by_type_review_num">
                      <div class="l_num">{{ cleanlinessService }}</div>
                      <div :class="`num_${cleanlinessService}`">
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div class="by_type_review" v-if="foodService !== false">
                    <!-- div for food -->
                    Food
                    <div class="by_type_review_num">
                      <div class="l_num">{{ foodService }}</div>
                      <div :class="`num_${foodService}`"><span></span></div>
                    </div>
                  </div>
                  <div class="by_type_review" v-if="locationService !== false">
                    <!-- div for hotal location -->
                    Location
                    <div class="by_type_review_num">
                      <div class="l_num">{{ locationService }}</div>
                      <div :class="`num_${locationService}`"><span></span></div>
                    </div>
                  </div>
                  <div class="by_type_review" v-if="totalService !== false">
                    <!-- div for hotal service -->
                    Service
                    <div class="by_type_review_num">
                      <div class="l_num">{{ totalService }}</div>
                      <div :class="`num_${totalService}`"><span></span></div>
                    </div>
                  </div>
                  <div
                    class="by_type_review"
                    v-if="publicAreaService !== false"
                  >
                    <!-- div for public area service -->
                    Public Areas
                    <div class="by_type_review_num">
                      <div class="l_num">{{ publicAreaService }}</div>
                      <div :class="`num_${publicAreaService}`">
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="by_type_review"
                    v-if="roomQualityService !== false"
                  >
                    <!-- div for Room qality service -->
                    Room quality
                    <div class="by_type_review_num">
                      <div class="l_num">{{ roomQualityService }}</div>
                      <div :class="`num_${roomQualityService}`">
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="by_reviews custom_reviews"
                  v-if="hotel.hotel.last_hotel_rates.length > 0"
                >
                  <div
                    class="by_review"
                    v-if="hotel.hotel.last_hotel_rates.length >= 1"
                  >
                    <i class="demo-icon icon-user"></i>
                    
                    
                    <div class="by_review_date custom_review">
                    <h4 class="custom_name">{{ hotel.hotel.last_hotel_rates[0].Full_Name }}</h4>
                      {{
                        getMomentDate(
                          hotel.hotel.last_hotel_rates[0].Rate_Date,
                          "DD/MM/YYYY"
                        )
                      }}
                      <p>
                      <!-- user comments will add here -->
                    </p>
                    </div>
                    
                  </div>
                  <div
                    class="by_review"
                    v-if="hotel.hotel.last_hotel_rates.length >= 2"
                  >
                    <i class="demo-icon icon-user"></i>
                    
                    <div class="by_review_date">
                    <h4 class="custom_name">{{ hotel.hotel.last_hotel_rates[1].Full_Name }}</h4>
                      {{
                        getMomentDate(
                          hotel.hotel.last_hotel_rates[1].Rate_Date,
                          "DD/MM/YYYY"
                        )
                      }}
                      <p>
                      <!-- user comments will add here -->
                    </p>
                    </div>
                    
                  </div>
                  <div
                    class="by_review"
                    v-if="hotel.hotel.last_hotel_rates.length >= 3"
                  >
                    <i class="demo-icon icon-user"></i>
                    
                    <div class="by_review_date">
                    <h4 class="custom_name">{{ hotel.hotel.last_hotel_rates[2].Full_Name }}</h4>
                      {{
                        getMomentDate(
                          hotel.hotel.last_hotel_rates[2].Rate_Date,
                          "DD/MM/YYYY"
                        )
                      }}
                       <p>
                      <!-- user comments will add here -->
                    </p>
                    </div>
                   
                  </div>
                  <div
                    class="by_review"
                    v-if="hotel.hotel.last_hotel_rates.length >= 4"
                  >
                    <i class="demo-icon icon-user"></i>
                    
                    <div class="by_review_date">
                    <h4 class="custom_name">{{ hotel.hotel.last_hotel_rates[3].Full_Name }}</h4>
                      {{
                        getMomentDate(
                          hotel.hotel.last_hotel_rates[3].Rate_Date,
                          "DD/MM/YYYY"
                        )
                      }}
                      <p>
                      <!-- user comments will add here -->
                    </p>
                    </div>
                    
                  </div>
                  <div class="by_review_button">
                    <a href="#"
                      >Upload more comments
                      <i class="demo-icon icon-left-open-big"></i
                    ></a>
                  </div>
                </div>
              </div>

              <!-- <div class="by_map" id="location">
                    <h2>מיקום המלון</h2>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6944.708720888601!2d34.912866066381994!3d29.506028589689638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1500739086a4329d%3A0x86f5f4134aece10f!2zT3JjaGlkIEVpbGF0IC0g15DXldeo15vXmdeT15DXlCDXkNeZ15zXqg!5e0!3m2!1sen!2sil!4v1617094694571!5m2!1sen!2sil"
                        width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                    <p>המלון ממוקם בחוף אלמוג , מול המצפה התת ימי . על חוף אלמוג מול הים , בין הרי המדבר האדמדמים ומי המפרץ התכולים, שוכן לו גן עדן קסום- מלון אורכידאה! -חוף המגדלור לשרות אורחי המלון, נמצא במרחק 500 מטר מהמלון, הכניסה ללא תשלום, בהצגת כרטיס
                        החדר.
                    </p>
                </div> -->
              <div
                class="by_map"
                id="location"
                v-if="hotel.hotel.latitude && hotel.hotel.longitude"
              >
                <h2>Hotel location</h2>

                <div style="height: 450px; width: 100%; float: left">
                  <LMap
                    :zoom="15"
                    :center="[
                      parseFloat(hotel.hotel.latitude),
                      parseFloat(hotel.hotel.longitude),
                    ]"
                  >
                    <LTileLayer :url="lmapURL"></LTileLayer>
                    <LMarker
                      :lat-lng="[
                        parseFloat(hotel.hotel.latitude),
                        parseFloat(hotel.hotel.longitude),
                      ]"
                    >
                      <LIcon
                        ><span
                          ><i
                            class="fa fa-map-marker"
                            style="color: red; font: bold"
                            aria-hidden="true"
                          ></i
                          >{{ hotel.hotel.hotel_name_en }}</span
                        ></LIcon
                      >
                    </LMarker>
                  </LMap>
                </div>
                <!-- <p>
                  המלון ממוקם בחוף אלמוג , מול המצפה התת ימי . על חוף אלמוג מול
                  הים , בין הרי המדבר האדמדמים ומי המפרץ התכולים, שוכן לו גן עדן
                  קסום- מלון אורכידאה! -חוף המגדלור לשרות אורחי המלון, נמצא
                  במרחק 500 מטר מהמלון, הכניסה ללא תשלום, בהצגת כרטיס החדר.
                </p> -->
              </div>
              <!-- by_map -->
            </div>
          </div>
          <!-- <div class="important">
          <h2>Things that are important to know</h2>
          <div class="by_lists">
            <h3>כללי</h3>
            <div class="by_list">
              <i class="demo-icon icon-clock"></i> צ'ק-אין: 14:00 - 18:00
            </div>
            <div class="by_list">
              <i class="demo-icon icon-school"></i> מתאים לילדים ולתינוקות
            </div>
            <div class="by_list">
              <i class="demo-icon icon-clock"></i> ללא עישון
            </div>
            <div class="by_list">
              <i class="demo-icon icon-paw"></i> מותר להביא חיות מחמד
            </div>
            <a href="#inline" data-lity>הצג הכל</a>
            <div id="inline" class="lity-hide">
              <h3>כללי</h3>
              <div class="by_list">
                <i class="demo-icon icon-clock"></i> צ'ק-אין: 14:00 - 18:00
              </div>
              <div class="by_list">
                <i class="demo-icon icon-school"></i> מתאים לילדים ולתינוקות
              </div>
              <div class="by_list">
                <i class="demo-icon icon-clock"></i> ללא עישון
              </div>
              <div class="by_list">
                <i class="demo-icon icon-paw"></i> מותר להביא חיות מחמד
              </div>
            </div>
          </div>
          <div class="by_lists">
            <h3>בריאות ובטיחות</h3>
            <div class="by_list">
              <i class="demo-icon icon-clock"></i> צ'ק-אין: 14:00 - 18:00
            </div>
            <div class="by_list">
              <i class="demo-icon icon-school"></i> מתאים לילדים ולתינוקות
            </div>
            <div class="by_list">
              <i class="demo-icon icon-clock"></i> ללא עישון
            </div>
            <div class="by_list">
              <i class="demo-icon icon-paw"></i> מותר להביא חיות מחמד
            </div>
            <a href="#inline2" data-lity>הצג הכל</a>
            <div id="inline2" class="lity-hide">
              <h3>בריאות ובטיחות</h3>
              <div class="by_list">
                <i class="demo-icon icon-clock"></i> צ'ק-אין: 14:00 - 18:00
              </div>
              <div class="by_list">
                <i class="demo-icon icon-school"></i> מתאים לילדים ולתינוקות
              </div>
              <div class="by_list">
                <i class="demo-icon icon-clock"></i> ללא עישון
              </div>
              <div class="by_list">
                <i class="demo-icon icon-paw"></i> מותר להביא חיות מחמד
              </div>
            </div>
          </div>
          <div class="by_lists">
            <h3>Cancellation Policy</h3>
            <div class="by_list" v-if="hotel.hotel.cancellation_terms_en">
          

              <p style="white-space: pre-line">{{hotel.hotel.cancellation_terms_en}}</p>

              <a href="#inline3" data-lity>for further details</a>
              <div id="inline3" class="lity-hide">
                <div class="by_list">
                  <h3>Cancellation Policy</h3>
                

                  <p style="white-space: pre-line">{{hotel.hotel.cancellation_terms_en}}</p>

                  <div class="note lightblue">
                    <strong>אישור הזמנה</strong> החזר כספי מלא אם הצ'ק-אין הוא
                    בעוד 14 ימים לפחות
                  </div>
                  <div class="note yellow">
                    <strong>48 שעות מאוחר יותר</strong> החזר כספי בגובה 50%,
                    בניכוי דמי שירות
                  </div>
                  <div class="note red">
                    <strong>יום לפני ההגעה</strong> אין החזר כספי
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="resize-sensor"></div>
        </div> -->
          <!-- <div class="by_faq">
          <h2>שאלות נפוצות</h2>
          <ul class="accordion">
            <li>
              <div class="arrow">
                <i class="demo-icon icon-down-open-big"></i>
              </div>
              <a class="toggle" href="javascript:void(0);"
                >מתי משלמים עבור ההזמנה?</a
              >
              <div class="inner">
                <p>אתם אמורים לשלם בכניסה למלון בזמן הצ'ק אין</p>
              </div>
            </li>

            <li>
              <div class="arrow">
                <i class="demo-icon icon-down-open-big"></i>
              </div>
              <a class="toggle" href="javascript:void(0);"
                >האם אפשר להביא חיות למלון?</a
              >
              <div class="inner">
                <p>
                  במלון חל איסור בעלי חיים מלבד אנשים עם צרכים מיוחדים בעלי
                  אישור
                </p>
              </div>
            </li>
            <li>
              <div class="arrow">
                <i class="demo-icon icon-down-open-big"></i>
              </div>
              <a class="toggle" href="javascript:void(0);"
                >מתי משלמים עבור ההזמנה?</a
              >
              <div class="inner">
                <p>אתם אמורים לשלם בכניסה למלון בזמן הצ'ק אין</p>
              </div>
            </li>

            <li>
              <div class="arrow">
                <i class="demo-icon icon-down-open-big"></i>
              </div>
              <a class="toggle" href="javascript:void(0);"
                >האם אפשר להביא חיות למלון?</a
              >
              <div class="inner">
                <p>
                  במלון חל איסור בעלי חיים מלבד אנשים עם צרכים מיוחדים בעלי
                  אישור
                </p>
              </div>
            </li>
          </ul>
        </div> -->
        </div>
        <div class="by_more_hotels_en" v-if="moreHotel.length > 0">
          <div class="width">
            <h3>More Hotels</h3>
            <div
              class="by_more_hotel_en"
              v-for="(more, index) in moreHotel"
              :key="index"
              style="cursor: pointer"
              @click="goToHotel(more.hotel_id)"
            >
              <div
                class="by_more_hotel_image_en"
                v-if="more.img_src && more.img_src !== ''"
              >
                <img :src="more.img_src" alt="banner" />
              </div>
              <div
                class="by_more_hotel_txt_en"
                v-if="more.title_en && more.title_en !== ''"
              >
                <h4>{{ more.title_en }}</h4>
                <p v-if="more.address_en && more.address_en !== ''">
                  {{ more.address_en }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="error_msg" v-if="errorMsg">{{ errorMsg }}</div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "./header.vue";
import Footer from "./footer.vue";
import jQuery from "jquery";
import { gmapApi } from "vue2-google-maps";
import axios from "axios";
import moment from "moment";
import lightGallery from "./vue-lightGallery.vue";
import "lg-zoom.js";
import "lg-fullscreen.js";
import "lg-thumbnail.js";
import "lg-autoplay.js";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

 
export default {
  name: "Home",
  newArr: "",
  mounted() {
   
  },

  created() {
    let hotelId = "";

    //set by default language
    if (
      localStorage.getItem("language") === undefined ||
      localStorage.getItem("language") === null ||
      localStorage.getItem("language") === ""
    ) {
      localStorage.setItem("language", "HE");
      this.language = localStorage.getItem("language");
    } else {
      this.language = localStorage.getItem("language");
    }


    hotelId = this.$route.params.id;
    this.myHotelId = hotelId;
    this.getHotels(hotelId);


  },
  data() {
    return {
      hotels: [],
      errorMsg: "",
      mapURL: "https://www.google.com/maps/embed/v1/place",
      mapAuthKey: "AIzaSyBEyzmxyOvHKdFEsQuurDvfb7JhttatY7E",
      latitude: "",
      longitude: "",
      language: "",
      imgs: [],
      roomData: {},
      promotionData: [],
      sliders: [],
      holidays: [],
      allHotels: [],
      thisWeekPromotionHe: [],
      thisWeekPromotionEn: [],
      nextWeekPromotion: [],
      holidayPromotion: [],
      promotionLoading: false,
      promotion_tab: "promotion_tab",
      lmapURL: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      // plugins: [lgThumbnail, lgZoom],
      loading: false,
      roomQualityService: false,
      foodService: false,
      totalService: false,
      locationService: false,
      cleanlinessService: false,
      publicAreaService: false,
      minDate: "",
      maxDate: "",
      selectedEntrydate: "",
      selectedReleasedate: "",
      slide: 0,
      sliding: null,
      options: {
        rewind: true,
        width: 800,
        perPage: 1,
        gap: "1rem",
        perMove: 1,
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      date: {
        start: "",
        end: "",
      },
      currentDate: "",
      moreHotel: [],
      adults: 2,
      children: 0,
      infants: 0,
      myHotelId: "",
      birthday: "",
      date1: new Date(2016, 2, 1),
      defaultLoader:false,
      loadedCheck:false,
      thisWeekFlag:false,
      nextWeekFlag:false,
      comingWeekFlag:false,
      isShowAll:true
    };
  },
  methods: {
    linkClass(idx) {
      return ["promotion_tab", "deals"];
    },
    showDescription(){
      this.isShowAll=!this.isShowAll
    },
    goToHotel(searchId) {
      if (this.$route.params.id.toString() !== searchId.toString()) {
        this.$router.push({ path: "/hotel/" + searchId }).catch((error) => {
          console.log(error);
        });
      }
    },
    setLoad(flag){
    
      if(flag === 'comingWeekEnd' && this.comingWeekFlag == false){
       this.defaultLoader=true
       setTimeout(()=>{
        this.defaultLoader=false;
        this.comingWeekFlag=true;
      },3000)
      }
      
      if(flag === 'nextWeekEnd' && this.nextWeekFlag == false){
       this.defaultLoader=true
       setTimeout(()=>{
        this.defaultLoader=false;
        this.nextWeekFlag=true;
      },3000)
      }
      
      
     
    },
    calculateAverage() {
      for (let hotel of this.hotels) {
        if (
          hotel.hotel.last_hotel_rates &&
          hotel.hotel.last_hotel_rates.length > 0
        ) {
          let foodServiceTotal = 0;
          let locationServiceTotal = 0;
          let cleanlinessServiceTotal = 0;
          let serviceTotal = 0;
          let roomQualityServiceTotal = 0;
          let publicAreaServiceTotal = 0;
          let foodServiceCounter = 0;
          let locationServiceCounter = 0;
          let cleanlinessServiceCounter = 0;
          let serviceCounter = 0;
          let roomServiceCounter = 0;
          let publicAreaServiceCounter = 0;
          for (let service of hotel.hotel.last_hotel_rates) {
            if (service.Cleanliness) {
              cleanlinessServiceTotal =
                cleanlinessServiceTotal + parseFloat(service.Cleanliness);
              cleanlinessServiceCounter = cleanlinessServiceCounter + 1;
            }
            if (service.Hotel_Location) {
              locationServiceTotal =
                locationServiceTotal + parseFloat(service.Hotel_Location);
              locationServiceCounter = locationServiceCounter + 1;
            }
            if (service.Food_and_Beverage) {
              foodServiceTotal =
                foodServiceTotal + parseFloat(service.Food_and_Beverage);
              foodServiceCounter = foodServiceCounter + 1;
            }
            if (service.Service) {
              serviceTotal = serviceTotal + parseFloat(service.Service);
              serviceCounter = serviceCounter + 1;
            }

            if (service.Room_Quality) {
              roomQualityServiceTotal =
                roomQualityServiceTotal + parseFloat(service.Room_Quality);
              roomServiceCounter = roomServiceCounter + 1;
            }
            if (service.Public_Areas) {
              publicAreaServiceTotal =
                publicAreaServiceTotal + parseFloat(service.Public_Areas);
              publicAreaServiceCounter = publicAreaServiceCounter + 1;
            }
          }

          //calculate average
          this.foodService = Math.round(foodServiceTotal / foodServiceCounter);
          this.roomQualityService = Math.round(
            roomQualityServiceTotal / roomServiceCounter
          );
          this.cleanlinessService = Math.round(
            cleanlinessServiceTotal / cleanlinessServiceCounter
          );
          this.totalService = Math.round(serviceTotal / serviceCounter);
          this.locationService = Math.round(
            locationServiceTotal / locationServiceCounter
          );
          this.publicAreaService = Math.round(
            publicAreaServiceTotal / publicAreaServiceCounter
          );

          break;
        }
      }
    },
    reserveHotel() {
      window.location.assign(
        `https://res.hotels.co.il/reservation/search.php?lang=${this.language.toLowerCase()}&currency=${this.language.toLowerCase()}&hedid=0&termID=0&hhid=${
          this.myHotelId
        }&searchBy=hotel&reff=advsrc&fromDate=${moment(this.date.start).format(
          "YYYY-MM-DD"
        )}&toDate=${moment(this.date.end).format("YYYY-MM-DD")}&adults=${
          this.adults
        }&children=${this.children}&infants=${this.infants}&search=Search`
      );
    },

    getHotels(hotelId) {
      //get all hotels data
      this.loading = true;

      axios
        .get(
          "https://res.hotels.co.il/web-service/get-hotels-autocomplete.php?load=bytech"
        )
        .then((response) => {
          this.allHotels = response.data;
          let found = "not found";
          for (let hotel of this.allHotels) {
            if (hotel.hotel_id.toString() === hotelId.toString()) {
              let wp_id = hotel.wp_id;
              localStorage.setItem("hotelId", hotelId);
              localStorage.setItem("wpId", wp_id);

              found = 1;
              axios
                .get(
                  "https://res.hotels.co.il/json/hotels-api/hotel" +
                    wp_id +
                    ".json"
                )
                .then(async (response) => {
                  this.hotels.push(response.data);
                
                  if (this.hotels[0].hotel.component === "4") {
                    localStorage.setItem(
                      "g4Id",
                      this.hotels[0].hotel.g4_hotel_id
                    );
                  } else {
                    localStorage.setItem("g4Id", false);
                  }
                 this.loading = false;
                 
               await  this.getThisWeekPromotion();
               await  this.getNextsWeekPromotion();
                await this.getHolidayPromotion();
                 await this.readScript();  
                  this.calculateAverage();

                  this.getMoreHotel();

                  
                
                })
                .catch((error) => {
                  console.log(error);
                  this.loading = false;
                  this.hotels = [];
                  this.errorMsg = "Sorry no data found for this hotel id.";
                });
              break;
            } else {
              found = 0;
              continue;
            }
          }
          if (found == 0) {
            this.loading = false;
            this.errorMsg = "Sorry no data found for this hotel id.";
          }
          //   this.allHotels.push(...response.data);
        })
        .catch((error) => {
          console.log(error);
          this.errorMsg = "Error while fetching data.";
        });
    },
    getMoreHotel() {
      for (let hotel of this.hotels) {
        if (
          hotel.hotel.loc_related_hotels &&
          hotel.hotel.loc_related_hotels.length > 0
        ) {
          for (let hotelId of hotel.hotel.loc_related_hotels) {
            for (let hotel of this.allHotels) {
              if (hotel.wp_id.toString() === hotelId.toString()) {
                this.moreHotel.push(hotel);
              }
            }
          }
        }
      }
    },
    updateCheckIn(date) {
     
    },
    updateCheckout(date) {
    
    },
    async getThisWeekPromotion() {
      //get default hebrew data

      this.thisWeekPromotionHe = [];
      this.thisWeekPromotionEn = [];

      this.promotionLoading = true;
      this.loadedCheck=false
      if (this.language === "HE") {
        
        //if hotel using promotion from G4
        if (this.hotels[0].hotel.component === "4") {
          let package1 = [];
          let package2 = [];
          let package5 = [];

          //request for package 1
          await axios
            .get(
              "https://res.hotels.co.il/json/g4_packages.php?hotel_id=" +
                this.hotels[0].hotel.g4_hotel_id +
                "&package=1&weekend=this&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionHe.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 2

          await axios
            .get(
              "https://res.hotels.co.il/json/g4_packages.php?hotel_id=" +
                this.hotels[0].hotel.g4_hotel_id +
                "&package=2&weekend=this&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionHe.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 5
          await axios
            .get(
              "https://res.hotels.co.il/json/g4_packages.php?hotel_id=" +
                this.hotels[0].hotel.g4_hotel_id +
                "&package=5&weekend=this&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionHe.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //if hotel using promotion from ISROTEL
        } else if (this.hotels[0].hotel.component === "5") {
          let package1 = [],
            package2 = [],
            package5 = [];

          //request for package 1
          await axios
            .get(
              "https://res.hotels.co.il/json/isrotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.isrotel_hotel_id +
                "&package=1&weekend=this&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionHe.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 2
          await axios
            .get(
              "https://res.hotels.co.il/json/isrotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.isrotel_hotel_id +
                "&package=2&weekend=this&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionHe.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 5
          await axios
            .get(
              "https://res.hotels.co.il/json/isrotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.isrotel_hotel_id +
                "&package=5&weekend=this&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionHe.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //if hotel using promotion from MINIHOTEL
        } else if (this.hotels[0].hotel.component === "6") {
          let package1 = [],
            package2 = [],
            package5 = [];
          //request for package 1
          await axios
            .get(
              "https://res.hotels.co.il/json/minihotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.minihotel_hotel_id +
                "&package=1&weekend=this&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionHe.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 2
          await axios
            .get(
              "https://res.hotels.co.il/json/minihotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.minihotel_hotel_id +
                "&package=2&weekend=this&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionHe.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 5
          await axios
            .get(
              "https://res.hotels.co.il/json/minihotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.minihotel_hotel_id +
                "&package=5&weekend=this&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionHe.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        //default english data

        if (this.hotels[0].hotel.component === "4") {
          
          let package1 = [];
          let package2 = [];
          let package5 = [];
          //request for package 1
          await axios
            .get(
              "https://res.hotels.co.il/json/g4_packages.php?hotel_id=" +
                this.hotels[0].hotel.g4_hotel_id +
                "&package=1&weekend=this&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionEn.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 2

          await axios
            .get(
              "https://res.hotels.co.il/json/g4_packages.php?hotel_id=" +
                this.hotels[0].hotel.g4_hotel_id +
                "&package=2&weekend=this&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionEn.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 5
          await axios
            .get(
              "https://res.hotels.co.il/json/g4_packages.php?hotel_id=" +
                this.hotels[0].hotel.g4_hotel_id +
                "&package=5&weekend=this&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionEn.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //if hotel using promotion from ISROTEL
        } else if (this.hotels[0].hotel.component === "5") {
          let package1 = [],
            package2 = [],
            package5 = [];

          //request for package 1
          await axios
            .get(
              "https://res.hotels.co.il/json/isrotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.isrotel_hotel_id +
                "&package=1&weekend=this&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionEn.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {});

          //request for package 2
          await axios
            .get(
              "https://res.hotels.co.il/json/isrotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.isrotel_hotel_id +
                "&package=2&weekend=this&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionEn.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 5
          await axios
            .get(
              "https://res.hotels.co.il/json/isrotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.isrotel_hotel_id +
                "&package=5&weekend=this&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionEn.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //if hotel using promotion from MINIHOTEL
        } else if (this.hotels[0].hotel.component === "6") {
          let package1 = [],
            package2 = [],
            package5 = [];
          //request for package 1
          await axios
            .get(
              "https://res.hotels.co.il/json/minihotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.minihotel_hotel_id +
                "&package=1&weekend=this&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionEn.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 2
          await axios
            .get(
              "https://res.hotels.co.il/json/minihotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.minihotel_hotel_id +
                "&package=2&weekend=this&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionEn.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 5
          await axios
            .get(
              "https://res.hotels.co.il/json/minihotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.minihotel_hotel_id +
                "&package=5&weekend=this&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.thisWeekPromotionEn.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      this.loadedCheck=true
    },
    async getNextsWeekPromotion() {
      //if hotel using promotion from G4
      this.nextWeekPromotion = [];
      this.promotionLoading = true;
      this.loadedCheck=false;
      if (this.language === "HE") {
        if (this.hotels[0].hotel.component === "4") {
          
          let package2 = [],
            package3 = [];
          //request for package 2
          await axios
            .get(
              "https://res.hotels.co.il/json/g4_packages.php?hotel_id=" +
                this.hotels[0].hotel.g4_hotel_id +
                "&package=2&weekend=next&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.nextWeekPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 3
          await axios
            .get(
              "https://res.hotels.co.il/json/g4_packages.php?hotel_id=" +
                this.hotels[0].hotel.g4_hotel_id +
                "&package=3&weekend=next&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.nextWeekPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (this.hotels[0].hotel.component === "5") {
          let package2 = [],
            package3 = [];
          //request for package 2
          await axios
            .get(
              "https://res.hotels.co.il/json/isrotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.isrotel_hotel_id +
                "&package=2&weekend=next&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.nextWeekPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 3
          await axios
            .get(
              "https://res.hotels.co.il/json/isrotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.isrotel_hotel_id +
                "&package=3&weekend=next&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.nextWeekPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (this.hotels[0].hotel.component === "6") {
          let package2 = [],
            package3 = [];
          //request for package 2
          await axios
            .get(
              "https://res.hotels.co.il/json/minihotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.minihotel_hotel_id +
                "&package=2&weekend=next&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.nextWeekPromotion.push(response.data);
                
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 3
          await axios
            .get(
              "https://res.hotels.co.il/json/minihotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.minihotel_hotel_id +
                "&package=3&weekend=next&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.nextWeekPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        if (this.hotels[0].hotel.component === "4") {
          let package2 = [],
            package3 = [];
          //request for package 2
          await axios
            .get(
              "https://res.hotels.co.il/json/g4_packages.php?hotel_id=" +
                this.hotels[0].hotel.g4_hotel_id +
                "&package=2&weekend=next&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.nextWeekPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 3
          await axios
            .get(
              "https://res.hotels.co.il/json/g4_packages.php?hotel_id=" +
                this.hotels[0].hotel.g4_hotel_id +
                "&package=3&weekend=next&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.nextWeekPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (this.hotels[0].hotel.component === "5") {
          let package2 = [],
            package3 = [];
          //request for package 2
          await axios
            .get(
              "https://res.hotels.co.il/json/isrotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.isrotel_hotel_id +
                "&package=2&weekend=next&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.nextWeekPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 3
          await axios
            .get(
              "https://res.hotels.co.il/json/isrotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.isrotel_hotel_id +
                "&package=3&weekend=next&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.nextWeekPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (this.hotels[0].hotel.component === "6") {
      
          let package2 = [],
            package3 = [];
          //request for package 2
          await axios
            .get(
              "https://res.hotels.co.il/json/minihotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.minihotel_hotel_id +
                "&package=2&weekend=next&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.nextWeekPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //request for package 3
          await axios
            .get(
              "https://res.hotels.co.il/json/minihotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.minihotel_hotel_id +
                "&package=3&weekend=next&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.nextWeekPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    async getHolidayPromotion() {
      this.promotionLoading = true;
      if (this.language === "HE") {
        if (this.hotels[0].hotel.component === "4") {
          //for date 2021-12-02 to 2021-12-04
          await axios
            .get(
              "https://res.hotels.co.il/json/g4_packages.php?hotel_id=" +
                this.hotels[0].hotel.g4_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-04&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //for date 2021-12-02 to 2021-12-05
          await axios
            .get(
              "https://res.hotels.co.il/json/g4_packages.php?hotel_id=" +
                this.hotels[0].hotel.g4_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-05&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //for date 2021-12-06 to 2021-12-06
          await axios
            .get(
              "https://res.hotels.co.il/json/g4_packages.php?hotel_id=" +
                this.hotels[0].hotel.g4_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-06&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (this.hotels[0].hotel.component === "5") {
          //for date 2021-12-02 to 2021-12-04
          await axios
            .get(
              "https://res.hotels.co.il/json/isrotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.isrotel_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-04&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //for date 2021-12-02 to 2021-12-05
          await axios
            .get(
              "https://res.hotels.co.il/json/isrotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.isrotel_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-05&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //for date 2021-12-02 to 2021-12-06
          await axios
            .get(
              "https://res.hotels.co.il/json/isrotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.isrotel_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-06&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (this.hotels[0].hotel.component === "6") {
          //for date 2021-12-02 to 2021-12-04
          await axios
            .get(
              "https://res.hotels.co.il/json/minihotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.minihotel_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-04&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //for date 2021-12-02 to 2021-12-05
          await axios
            .get(
              "https://res.hotels.co.il/json/minihotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.minihotel_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-05&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //for date 2021-12-02 to 2021-12-06
          await axios
            .get(
              "https://res.hotels.co.il/json/minihotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.minihotel_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-06&currency=he"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                //  package1=response.data
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        if (this.hotels[0].hotel.component === "4") {
          //for date 2021-12-02 to 2021-12-04
          await axios
            .get(
              "https://res.hotels.co.il/json/g4_packages.php?hotel_id=" +
                this.hotels[0].hotel.g4_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-04&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //for date 2021-12-02 to 2021-12-05
          await axios
            .get(
              "https://res.hotels.co.il/json/g4_packages.php?hotel_id=" +
                this.hotels[0].hotel.g4_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-05&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //for date 2021-12-06 to 2021-12-06
          await axios
            .get(
              "https://res.hotels.co.il/json/g4_packages.php?hotel_id=" +
                this.hotels[0].hotel.g4_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-06&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (this.hotels[0].hotel.component === "5") {
          //for date 2021-12-02 to 2021-12-04
          await axios
            .get(
              "https://res.hotels.co.il/json/isrotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.isrotel_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-04&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //for date 2021-12-02 to 2021-12-05
          await axios
            .get(
              "https://res.hotels.co.il/json/isrotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.isrotel_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-05&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //for date 2021-12-02 to 2021-12-06
          await axios
            .get(
              "https://res.hotels.co.il/json/isrotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.isrotel_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-06&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (this.hotels[0].hotel.component === "6") {
          //for date 2021-12-02 to 2021-12-04
          await axios
            .get(
              "https://res.hotels.co.il/json/minihotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.minihotel_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-04&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //for date 2021-12-02 to 2021-12-05
          await axios
            .get(
              "https://res.hotels.co.il/json/minihotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.minihotel_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-05&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          //for date 2021-12-02 to 2021-12-06
          await axios
            .get(
              "https://res.hotels.co.il/json/minihotel_packages.php?hotel_id=" +
                this.hotels[0].hotel.minihotel_hotel_id +
                "&package=4&fromDate=2021-12-02&toDate=2021-12-06&currency=en"
            )
            .then((response) => {
              if (response.data.Error == false || response.data.Error == null) {
                this.holidayPromotion.push(response.data);
              }
              this.promotionLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      this.loadedCheck=true
    },
    
    getHolidays() {
      axios
        .get("https://res.hotels.co.il/json/get-holidays-json.php")
        .then((response) => {
          this.holidays = response.data;
          // this.setDate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCalendarData(hotelId) {
      axios
        .get(
          "https://res2.hotels.co.il/g4/create-data/get-day-availability.php?hotelID=" +
            hotelId +
            "&currency=he&formatDate&dataString",
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((response) => {
      
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setRoom(roomDetail) {
      this.roomData = roomDetail;
    },

    getRatingCount(ratingCount) {
      let str = "★";
      if (ratingCount > 0) {
        for (let i = 1; i <= ratingCount; i++) {
          str = str + "★";
        }
        return str;
      }
    },
    getMomentDate(date, format) {
      return moment(date).format(format);
    },
    checkHightLights() {
      let highlightCount;
      for (let hotel of this.hotels) {
        for (let highlight of hotel.hotel.hotel_highlights) {
          if (
            highlight.hotel_highlight_en === "" &&
            highlight.hotel_highlight_he === ""
          ) {
            highlightCount = 0;
          } else {
            highlightCount++;
          }
        }
        if (highlightCount == 0) {
          return false;
        } else if (highlightCount >= 1) {
          return true;
        }
      }
    },

    renderMap: (lat, long) => {
      this.latitude = lat;
      this.longitude = long;
      return (
        this.mapURL +
        "?key=" +
        this.mapAuthKey +
        "&q=" +
        this.latitude +
        "," +
        this.longitude
      );
    },

    openGallery(index) {
      this.$refs.lightGallery[0].showImage(index);
    
    },
    setDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      // 15th two months prior
      this.minDate = new Date(today);

      this.date.start = moment();
      this.currentDate = moment();
      this.date.end = moment().add(2, "days");

    },
    readScript() {
      let recaptchaScript = document.createElement("script");
      // recaptchaScript.setAttribute('src', 'http://localhost:8080/js/custom.js')
      recaptchaScript.setAttribute("src", "./../js/custom.js");
      document.head.appendChild(recaptchaScript);
    },
  },
  components: {
    Header: Header,
    Footer: Footer,
    lightGallery: lightGallery,
    LMap: LMap,
    LTileLayer: LTileLayer,
    LMarker: LMarker,
    LIcon: LIcon,
    ClipLoader:ClipLoader
    // VueDatePicker:VueDatePicker,

    
  },

  computed: {
    google: gmapApi,
    modalGallerys() {
      var arr = [];

      this.hotels[0].hotel.hotel_gallery.resources.forEach((value, index) => {
        let obj = {
          src: value.src,
          thumb: value.src,
        };
        arr.push(obj);
      });
      return arr;
    },
  },
  watch: {
    "$route.params.id": function (id) {
      console.log(id);

      this.$router.go();

      
    },
  },
};
</script> 

<style>

.all_type_reviews.custom_type_review .by_type_review_num .num_8 span {
    width: 80%;
    float: left;
}
</style>
