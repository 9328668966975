<template>
      <footer>
        <div class="top_line">
            <div class="width">
                <div class="share_icons">
                    <a href="#"><i class="demo-icon icon-facebook"></i></a>
                    <a href="#"><i class="demo-icon icon-instagram"></i></a>
                    <a href="#"><i class="demo-icon icon-youtube-play"></i></a>
                </div>
                <div class="members_club">
                    <p>הרשמו לקבלת מבצעים והטבות מהוטלס<br> הנחות עד 60% הנחה >>
                    </p>
                    <form name="members" id="members" method="post">
                        <div class="email_line"> <span class="email_button">
                            <input type="submit" name="SendMember" value="צרף אותי" onclick="submitMemberForm();return false;">
                            </span>
                            <input type="Text" name="Email" size="15" class="email_input" placeholder="נא להכניס דואר אלקטרוני">
                        </div>
                        <div id="message_ajax"></div>
                        <input name="IP" type="hidden" value="212.179.247.58">
                        <input name="URL" type="hidden" value="http://www.hotels.co.il/index.cfm">
                        <input name="TypeName" type="hidden" value="חברי מועדון">
                        <input name="TypeID" type="hidden" value="1">
                    </form>
                </div>
            </div>
        </div>
        <div class="width">
            <div class="menu_footer">
                <h2>כללי</h2>
                <ul>
                    <li><a href="#">מי אנחנו</a></li>
                    <li><a href="#">איך משתמשים באתר</a></li>
                    <li><a href="#">צור קשר</a></li>
                    <li><a href="#">תיק ההזמנות</a></li>
                    <li><a href="#">Israel Hotels</a></li>
                    <li><a href="#">תקנון אתר</a></li>
                    <li><a href="#">לוח חופשות חגים</a></li>
                    <li><a href="#">הופעות</a></li>
                    <li><a href="#">הצהרת נגישות</a></li>
                    <li><a href="#">מדיניות הפרטיות</a></li>
                </ul>
            </div>
            <div class="menu_footer">
                <h2>מלונות</h2>
                <ul>
                    <li><a href="#">מלונות באילת</a></li>
                    <li><a href="#">מלונות בים המלח</a></li>
                    <li><a href="#">מלונות בתל אביב</a></li>
                    <li><a href="#">מלונות בירושלים</a></li>
                    <li><a href="#">מלונות בחיפה</a></li>
                    <li><a href="#">מלונות בטבריה</a></li>
                    <li><a href="#">מלונות באשקלון</a></li>
                    <li><a href="#">מלונות באשדוד</a></li>
                    <li><a href="#">מלונות במצפה רמון</a></li>
                </ul>
            </div>
        </div>
        <div class="resize-sensor">
            <div class="resize-sensor-expand"></div>
        </div>
        <!-- </div> -->
        <!-- </div> -->
    </footer>
   
</template>

<script>

export default {
  name: "Footer"
}
</script>

<style>
</style>